import React, { Fragment, useState, useEffect } from 'react'
import { Card, CardBody, Button, Col, Row, FormGroup, Label, Input, Badge } from 'reactstrap'
import { MateriaisGet, MateriaisGetRecentes, OrdensGet } from '../../../Service/ApiService'
import { BsEyeFill, BsPencil, BsPlus, BsSearch } from 'react-icons/bs'
import { MdContentCopy } from 'react-icons/md'

import { useDispatch, PageTitle, i18n, ReactCSSTransitionGroup, ReactDatatable, useSelector, Select, moment } from '../../../Components'
import AsyncSelect from 'react-select/async'
import { getUser } from '../../../Reducers/storageApp'

const initValues = {
  dhInicio: new Date().toISOString().substr(0, 10),
  dhTermino: new Date().toISOString().substr(0, 10),
  nrLote: '',
  ativo: true,
  pedCompra: '',
  numeroOV: '',
  situacao: [
    { value: '10', label: i18n.t('order.query.filters.status.Sketch') },
    { value: '20', label: i18n.t('order.query.filters.status.WaitingConfirmation') },
    { value: '30', label: i18n.t('order.query.filters.status.AwaitingShipment') },
    { value: '40', label: i18n.t('order.query.filters.status.inProgress') },
    { value: '90', label: i18n.t('order.query.filters.status.closed') },
  ],
}

const opSituacao = [
  { value: '10', label: i18n.t('order.query.filters.status.Sketch') },
  { value: '20', label: i18n.t('order.query.filters.status.WaitingConfirmation') },
  { value: '30', label: i18n.t('order.query.filters.status.AwaitingShipment') },
  { value: '40', label: i18n.t('order.query.filters.status.inProgress') },
  { value: '90', label: i18n.t('order.query.filters.status.closed') },
  { value: '99', label: i18n.t('order.query.filters.status.canceled') },
]

function FormConsulta() {
  const user = getUser()
  const dispatch = useDispatch()
  const [DataGrid, setDataGrid] = useState()
  const [values, setValues] = useState(initValues)
  const [materiais, setMateriais] = useState()
  const [material, setMaterial] = useState('')
  const Par_Acessos = useSelector((state) => state.Par_Acessos)

  const getBadge = (situacao) => {
    switch (situacao) {
      case '10':
        return 'warning'

      case '20':
        return 'focus'
      case '30':
        return 'focus'
      case '40':
        return 'focus'

      case '90':
        return 'success'
      case '99':
        return 'dark'
      default:
        return 'danger'
    }
  }

  const columns = [
    { key: 'ordemIdExt', text: i18n.t('order.query.columns.ordemIdExt'), sortable: true },
    { key: 'pedCompra', text: i18n.t('order.query.columns.myOrder'), sortable: true },
    { key: 'nmParticipante', text: i18n.t('order.query.columns.nmParticipante'), align: 'left', sortable: true },
    { key: `${user?.tpVenda === 'I' ? 'nmTpOrdem' : 'numeroOV'}`, text: `${user?.tpVenda === 'I' ? i18n.t('order.query.columns.nmTpOrdem') : 'Contrato'}`, sortable: true },
    {
      key: 'nmSituacao',
      text: i18n.t('order.query.columns.situacao'),
      align: 'center',
      sortable: true,
      width: 120,
      cell: (d) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Badge className="btn-block" color={getBadge(d.situacao)}>
              {d.nmSituacao}
            </Badge>
          </div>
        )
      },
    },
    {
      key: 'dhCadastro',
      text: i18n.t('order.query.columns.dhCadastro'),
      sortable: true,
      cell: (d) => {
        return moment(d.dhCadastro).format('DD/MM/YYYY HH:mm')
      },
    },
    { key: 'nmUsuario', text: i18n.t('order.query.columns.nmUser'), sortable: true },
    {
      key: 'action',
      text: '#',
      className: 'action',
      width: 80,
      align: 'center',
      sortable: false,
      cell: (record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {user?.tpVenda === 'I' && (
              <button className="btn btn-outline-primary btn-square btn-sm" onClick={() => onCopy(record.ordemId)} style={{ marginRight: '5px' }} title={i18n.t('order.edit.form.buttonCopy')} disabled={!isAccess('OrdemCad')}>
                <MdContentCopy />
              </button>
            )}

            {record.situacao === '10' ? (
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  window.location.href = '/#/Admin/Order/Edit/' + record.ordemId
                }}
                style={{ marginRight: '5px' }}
                disabled={!isAccess('OrdemEdit')}
              >
                <BsPencil />
              </button>
            ) : (
              <button
                className="btn btn-outline-primary btn-square btn-sm"
                onClick={() => {
                  window.location.href = '/#/Admin/Order/Details/' + record.ordemId
                }}
                style={{ marginRight: '5px' }}
              >
                <BsEyeFill />
              </button>
            )}
          </div>
        )
      },
    },
  ]

  const config = {
    key_column: 'ordemId',
    page_size: 15,
    length_menu: [15, 25, 50],
    button: { excel: false },
    language: { loading_text: i18n.t('query.loading'), no_data_text: i18n.t('query.noData'), length_menu: i18n.t('query.display'), filter: i18n.t('query.search'), info: i18n.t('query.info'), pagination: { first: '<<', previous: '<', next: '>', last: '>>' } },
  }

  async function loadGrid(filters = { ...values }) {
    if (material) filters.materialId = material.value
    let _situacao = []
    values.situacao.forEach((item) => {
      _situacao.push(item.value)
    })

    filters.situacao = _situacao
    if (user.contaId !== 1) filters.contaId = user.contaId

    const response = await OrdensGet(filters)
    setDataGrid(response)
  }

  useEffect(() => {
    var _dtAtual = new Date()
    _dtAtual.setMinutes(_dtAtual.getMinutes() - _dtAtual.getTimezoneOffset())

    var _dtInicio = new Date()
    _dtInicio.setMinutes(_dtInicio.getMinutes() - _dtInicio.getTimezoneOffset())
    _dtInicio.setDate(_dtInicio.getDate() - 30)

    var _dtInicio2 = new Date(_dtInicio.getFullYear(), _dtInicio.getMonth(), 1)
    var filters = { situacao: '', dhInicio: _dtInicio2.toISOString().substr(0, 10), dhTermino: _dtAtual.toISOString().substr(0, 10), nrLote: '', ativo: true }

    setValues({ ...values, dhInicio: new Date(filters.dhInicio).toISOString().substr(0, 10) })
    loadGrid(filters)

    setTimeout(function () {
      onLoadMatRecentes()
    }, 1000)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }
  async function onChangeMaterial(ev) {
    if (ev) {
      setMaterial(ev)
    } else {
      setMaterial('')
    }
  }
  function onChangeCat(newValue, actionMeta) {
    const { name } = actionMeta
    setValues({ ...values, [name]: newValue })
  }

  async function onLoadMateriais(ev) {
    let items = []
    if (ev.trim().length > 3) {
      var result = await MateriaisGet({ ativo: true, textPesquisa: ev.trim() })

      if (result)
        result.forEach((item) => {
          if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
        })
    }
    setMateriais(items)
    return items
  }

  async function onLoadMatRecentes() {
    let items = []
    var result = await MateriaisGetRecentes({ nome: '' })
    if (result)
      result.forEach((item) => {
        if (item.nome.length > 0) items.push({ value: item.materialId, label: `${item.materialIdExt} - ${item.nome}`, ...item })
      })
    setMateriais(items)
  }
  async function onCopy(ordemId) {
    dispatch({ type: 'OrdemId', value: ordemId })
    window.location.href = `/#/admin/Order/Add`
  }

  function isAccess(codigo) {
    return Par_Acessos.some((f) => f.itens.some((i) => i.codigo === codigo))
  }

  return (
    <Fragment>
      <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
        <div className="body-tabs body-tabs-layout">
          <PageTitle heading={i18n.t('order.query.heading')} subheading={i18n.t('order.query.subheading')} icon="BsFillCartFill">
            {user?.tpVenda === 'I' && (
              <Button
                type="button"
                onClick={() => {
                  window.location.href = `/#/admin/Order/Add`
                }}
                className="btn-pill"
                color="success"
                style={{ marginRight: '15px' }}
                disabled={!isAccess('OrdemCad')}
              >
                <BsPlus size={18} />
                <span style={{ marginRight: '5px' }}>{i18n.t('order.query.buttonAdd')}</span>
              </Button>
            )}
          </PageTitle>
          <div className="page-content">
            <Card className="main-card mb-2">
              <CardBody>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.query.filters.dtStart')}</Label>
                      <Input type="date" onChange={onChange} value={values.dhInicio} name="dhInicio" id="dhInicio" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.query.filters.dtEnd')}</Label>
                      <Input type="date" onChange={onChange} value={values.dhTermino} name="dhTermino" id="dhTermino" />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    {user?.tpVenda === 'I' ? (
                      <FormGroup>
                        <Label>{i18n.t('order.query.filters.lot')}</Label>
                        <Input type="text" onChange={onChange} value={values.nrLote} name="nrLote" id="nrLote" />
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Label>Contrato</Label>
                        <Input type="text" onChange={onChange} value={values.numeroOV} name="numeroOV" id="numeroOV" />
                      </FormGroup>
                    )}
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>{i18n.t('order.query.filters.material')}</Label>

                      <AsyncSelect
                        placeholder="Digite para pesquisar"
                        noOptionsMessage={() => 'Nenhuma opção disponível'}
                        name="material"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        cacheOptions
                        loadOptions={onLoadMateriais}
                        onChange={(ev) => {
                          onChangeMaterial(ev)
                        }}
                        value={material}
                        defaultOptions={materiais}
                        isClearable
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <Label>{i18n.t('order.query.filters.myOrder')}</Label>
                      <Input type="text" onChange={onChange} value={values.pedCompra} name="pedCompra" id="pedCompra" />
                    </FormGroup>
                  </Col>
                  <Col md={10}>
                    <FormGroup>
                      <Label>{i18n.t('order.query.filters.status.title')} </Label>

                      <div style={{ display: 'flex' }}>
                        <Select placeholder="Selecione" noOptionsMessage={() => 'Nenhuma opção disponível'} onChange={onChangeCat} value={values.situacao} isMulti isClearable closeMenuOnSelect={false} name="situacao" className="basic-multi-select" classNamePrefix="select" options={opSituacao} />

                        <Button
                          type="button"
                          onClick={() => {
                            loadGrid()
                          }}
                          className="btn-pill"
                          color="primary"
                          id="btnPartPesquisar"
                          style={{ marginLeft: '5px' }}
                        >
                          <BsSearch size={18} />
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="table-responsive">
                    <ReactDatatable config={config} records={DataGrid} columns={columns} />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}

export default FormConsulta
